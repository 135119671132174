.bu-popover-wrapper{
    position: absolute;
    bottom: calc(100% + 15px);
    left: 50%;
    transform: translateX(-50%);
    max-width: 506px;
    width: 100%;
    .bu-file-upload{
        height: 222px;
        border: 1px solid var(--border-color);
        padding: 8px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--input-color);
        gap: 18px;
        text-align: center;
        .bu-icon{
            color: var(--gray-color);
        }
        p{
            color: var(--common-color);
            font-size: 16px;
            max-width: 264px;
            font-weight: 500;
            label{
                color: var(--yellow);
                cursor: pointer;
                text-decoration: underline;
                font-weight: 600;
            }
        }
        span{
            color: var(--light-gray-color);
            font-size: 14px;
            font-weight: 500;
        }
    }
    .bu-url-field{
        width: 100%;
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        .bu-btn{
            width: auto;
            min-width: 172px;
            min-height: 58px;
            color: var(--input-color);
            padding: 8px 16px;
            border-radius: 10px;
            border: 1px solid var(--border-color);
            background-color: var(--border-color);
            font-weight: 500;
            font-size: 16px;
        }
        input{
            border: 1px solid var(--border-color);
            background-color: var(--input-color);
            border-radius: 10px;
            width: 100%;
            min-height: 58px;
            font-weight: 500;
            font-size: 16px;
            padding: 8px 16px;
            text-align: center;
            color: var(--common-color);
            &:focus{
                outline: none;
                border: 1px solid var(--border-color);
            }
            &::placeholder{
                color: var(--common-color);
            }
        }
    }
}

// Image Uplaod
.bu-popover-image-upload-wrapper{
    min-height: 100px;
    border: 1px solid var(--border-color);
    padding: 9px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: var(--input-color);
    position: absolute;
    bottom: calc(100% + 15px);
    left: 50%;
    transform: translateX(-50%);
    width: 427px;
    // width: 100%;
    text-align: left;
    .bu-btn{
        padding: 9px 15px;
        color: var(--input-color);
        border-radius: 10px;
        border: 1px solid var(--border-color);
        background-color: var(--border-color);
        &.light-btn{
            border: 1px solid transparent;
            background-color: transparent;
            color: var(--text-secondary);
            &:hover{
                background-color: var(--main-bg-color);
            }
        }
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 20px;
        svg.bu-icon{
            color: var(--yellow-on-black);
        }
    }
}

.bu-uploaded-file{
    margin-bottom: 10px;
    padding: 8px 12px;
    border: 1px solid var(--gray-color);
    border-radius: 8px;
    color: var(--light-gray-color);
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.divider{
    border-top: 0.4px solid var(--divider);
}

.selected-file {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    p {
      display: flex;
      align-items: center;
    }
    strong {
      margin-left: 5px;
      font-weight: bold;
      color: #007bff; // Optional styling for emphasis
    }
  }
  