.build-up-main-container {
    // max-width: 400px !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;

    .build-up-main-content {
        margin: 0 auto;
        align-items: center;
        padding: 5px;

        .build-up-feature-button {
            border: none;
            width: 220px;
            height: 104px;
            padding: 18px 28px 18px 28px;
            gap: 10px;
            border-radius: 10px 0px 0px 0px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            border: 2px solid transparent;
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
            background-color: var(--input-color);
            cursor: pointer;
            box-shadow: 0 0 50px rgba(#000000, .05);
            &:hover {
                border-color:#F1F894;
            }

        }
    }



}