.bu-login-wrap
    position: fixed
    top: calc(50% - 107px)
    left: calc(50% - 107px)
    height: 214px
    width: 214px
    display: flex
    flex-direction: column
    flex-wrap: wrap
    gap: 4px
    padding: 8px
    justify-content: center
    align-items: center

.bu-logo-img
    width: 84px
    height: 72px

.bu-title
    font-size: 22px

.bu-sub-title
    font-size: 16px

.bu-menu-upgrade-btn
    width: 120px
    height: 40px
    border-radius: 6px
    background: linear-gradient(90.14deg, #7D68FF -6.93%, #8899FA 161.2%)
    font-family: 'Poppins', sans-serif
    font-size: 14px
    font-weight: 500
    border: none
    align-items: center
    display: flex
    padding: 4px 21px
    margin-top: 8px

.bu-cross-icon
    position: fixed
    cursor: pointer
    right: 8px
    top: 8px


.lds-ring,
.lds-ring div
    box-sizing: border-box

.lds-ring
    display: inline-block
    position: relative
    width: 80px
    height: 80px

.lds-ring div
    box-sizing: border-box
    display: block
    position: absolute
    width: 64px
    height: 64px
    margin: 8px
    border: 3px solid currentColor
    border-radius: 50%
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: currentColor transparent transparent transparent

.lds-ring div:nth-child(1)
    animation-delay: -0.45s

.lds-ring div:nth-child(2)
    animation-delay: -0.3s

.lds-ring div:nth-child(3)
    animation-delay: -0.15s

@keyframes lds-ring
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)


