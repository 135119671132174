.build-up-chat-screen-footer {
    padding: 23px 26px 18px 26px;
    border-radius: 30px 30px 8px 8px;
    text-align: center;
    background-color: var(--header-bg-color);
    position: relative;
    height: 134px;

    .icon-btn {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
        color: var(--border-color);
        cursor: pointer;
        button{
          background-color: transparent;
          cursor: pointer;
        }

        &:hover {
          background-color: var(--contrast-color);
        }
    }

    .build-up-input-section {
        padding: 8px;
        border-radius: 8px;
        align-items: center;
        display: flex;
        justify-content: space-around;
        gap: 8px;
        border: 2px solid var(--border-color);
        color: var(--border-color);

        .build-up-input-container {
            max-width: 612px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            border-radius: 8px;
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            background-color: var(--border-color);
            color: var(--input-color);
            textarea{
                color: inherit;
                &::placeholder{
                    color: inherit;
                }
            }
            .input-buttons{
                display: flex;
                align-items: center;
                padding-inline: 18px;
                color: inherit;
                .icon-btn{
                    color: inherit;
                    width: auto;
                    height: auto;
                    min-width: 32px;
                    justify-content: flex-start;
                    background-color: transparent !important;
                }
            }
        }
    }



    .build-up-footer-text {
        position: absolute;
        bottom: 14px;
        p {
            font-size: 13px;
            font-weight: 500;
            text-align: left;
            color: var(--text-color);
        }
    }

}

// Upgrade button
.build-up-upgrade-btn {
    width: 163px;
    height: 44px;
    border-radius: 4px;
    background: linear-gradient(90.14deg, #7D68FF -6.93%, #8899FA 161.2%);
    color: var(--text-dark);
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    border: none;
    align-items: center;
    display: flex;
    svg{
        min-width: 28px;
        min-height: 28px;
    }
}

.build-up-menu-upgrade-btn {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    background: linear-gradient(90.14deg, #7D68FF -6.93%, #8899FA 161.2%);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    border: none;
    align-items: center;
    display: flex;
    padding: 4px 21px;
    margin-bottom: 14px;
}

/* In a CSS or SCSS file */


.build-up-merlinExt-custom-scrollbar {
    --scrollbar-track: #e4e4e7;
    --scrollbar-thumb: #a1a1aa;
    --scrollbar-track-radius: var(--radius);
    --scrollbar-thumb-radius: var(--radius);
    --scrollbar-corner-radius: var(--radius);
    --scrollbar-width: 6px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.build-up-merlinExt-custom-scrollbar {
    scrollbar-width: auto;
    scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial);
}

.build-up-main-btn {
    width: 64px;
    height: 50px;
    position: fixed;
    top: calc(50% - 25px);
    right: 0px;
    background: #ECFB70 !important;
    color: black !important;
    font-weight: bolder;
    font-size: 24px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    opacity: 0.4;
    z-index: 999999;
}

.build-up-main-btn:hover {
    background: #ECFB70;
    opacity: 1;
}

#build-up-floating-window {
    border: unset;
    overflow-y: hidden;
    z-index: 999999 !important;
    position: fixed !important;
}

#build-up-floating-window>div.content {
    overflow-y: hidden;
}

#build-up-floating-window>div.content::-webkit-scrollbar {
    display: none;
}